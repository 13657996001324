exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-news-blog-js": () => import("./../../../src/templates/news-blog.js" /* webpackChunkName: "component---src-templates-news-blog-js" */),
  "component---src-templates-platform-js": () => import("./../../../src/templates/platform.js" /* webpackChunkName: "component---src-templates-platform-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-recycling-js": () => import("./../../../src/templates/recycling.js" /* webpackChunkName: "component---src-templates-recycling-js" */),
  "component---src-templates-responsible-sourcing-js": () => import("./../../../src/templates/responsible-sourcing.js" /* webpackChunkName: "component---src-templates-responsible-sourcing-js" */),
  "component---src-templates-scaling-js": () => import("./../../../src/templates/scaling.js" /* webpackChunkName: "component---src-templates-scaling-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */),
  "component---src-templates-the-pmr-difference-js": () => import("./../../../src/templates/the-pmr-difference.js" /* webpackChunkName: "component---src-templates-the-pmr-difference-js" */),
  "component---src-templates-toll-refining-js": () => import("./../../../src/templates/toll-refining.js" /* webpackChunkName: "component---src-templates-toll-refining-js" */)
}

